import { format, isValid } from "date-fns";
import { fr } from "date-fns/locale";

export function formatDate(date: string | number | Date) {
  if (!date) return "";

  const parsedDate = new Date(date);

  if (!isValid(parsedDate)) {
    console.error("Invalid date:", date);
    return "Date non valide";
  }

  return format(parsedDate, "dd MMM yyyy", { locale: fr });
}

export function formatDateWithCapitalMonth(date: any) {
  const formattedDate = new Date(date).toLocaleDateString("fr-FR", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });

  const [day, month, year] = formattedDate.split(" ");

  // Capitalize the first letter of the month
  const capitalizedMonth = month.charAt(0).toUpperCase() + month.slice(1);

  return `${day} ${capitalizedMonth} ${year}`;
}
