import { getHeaders } from "./headers";

export const getBlogPosts = async ({
  page = 1,
  limit = 10,
  published,
  featured,
}: {
  page?: number;
  limit?: number;
  published?: boolean;
  featured?: boolean;
}) => {
  try {
    const queryParams = new URLSearchParams({
      page: String(page),
      limit: String(limit),
      ...(featured !== undefined && { featured: String(featured) }),
      ...(published !== undefined && { published: String(published) }),
    });

    const response = await fetch(`/api/blog?${queryParams}`, {
      method: "GET",
      headers: getHeaders({ auth: false }),
    });

    if (!response.ok) {
      throw new Error(`Error fetching blog posts: ${response.statusText}`);
    }

    const data = await response.json();
    return data.posts;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
